import clsx from "clsx";
import { Link } from "gatsby";
import * as React from "react";
import * as styles from "./styles.module.css";

export default function ButtonHover({ href, onclick, size, children, icon, useGatsbyLink = true, targetBlank }) {
  let sizeClass;
  switch (size) {
    case "LARGE":
      sizeClass = styles.large;
      break;
    case "SMALL":
      sizeClass = styles.small;
      break;
    default:
      sizeClass = styles.small;
  }

  return (
    <>
      {
        useGatsbyLink &&
        <Link className={clsx(styles.button, sizeClass, icon && styles.iconcontainer)} to={href}>
          {children}
          {
            icon &&
            <img src={icon} alt='icon' />
          }
        </Link>
      }
      {
        !useGatsbyLink &&
        <a className={clsx(styles.button, sizeClass, icon && styles.iconcontainer)} href={href} onClick={onclick} target={targetBlank ? '_blank' : ''}>
          {children}
          {
            icon &&
            <img src={icon} alt='icon' />
          }
        </a>
      }
    </>
  );
}
